.selected {
  z-index: 1;
  position: absolute;
  width: 782px;
  max-width: 782px;
  min-height: 462px;
  max-height: 462px;
  box-shadow: 0px 0px 20px #2211481A;
  transition: cubic-bezier(0.67, 0.58, 0.9, 2.29) 300ms;
  border-top-left-radius: 50px !important;
  background: white;
  transition: all 200ms ease-in-out;
}

.preselected {
  opacity: 0.8;
  position: absolute;
  width: 782px;
  max-width: 782px;
  min-height: 462px;
  max-height: 462px;
  box-shadow: 0px 0px 20px #2211481A;
  transform: translateX(-21%) scale(0.8);
  transition: cubic-bezier(0.67, 0.58, 0.9, 2.29) 300ms;
  background: white;
  pointer-events: none;
  transition: all 200ms ease-in-out;
}
.proselected {
  opacity: 0.8;
  position: absolute;
  width: 782px;
  max-width: 782px;
  min-height: 462px;
  max-height: 462px;
  box-shadow: 0px 0px 20px #2211481A;
  transform: scale(0.8);
  transform: translateX(21%) scale(0.8);
  transition: cubic-bezier(0.67, 0.58, 0.9, 2.29) 300ms;
  background: white;
  pointer-events: none;
  transition: all 200ms ease-in-out;
}

.selectedDot {
  transform: scale(2.0) !important;
  color: #474747 !important;
  opacity: 1;
}

.preselectedDot {
  color: #474747 !important;
  opacity: 0.6;
}
.proselectedDot {
  color: #474747 !important;
  opacity: 0.6;
}

@media screen and (max-width: 750px) {
  .selected {
    min-width: 305px;
    max-width: 305px;
    min-height: 361px;
    max-height: 361px;
  }

  .preselected {
    min-width: 305px;
    max-width: 305px;
    min-height: 361px;
    max-height: 361px;
    transform: translateX(-105%) scale(1);
  }

  .proselected {
    min-width: 305px;
    max-width: 305px;
    min-height: 361px;
    max-height: 361px;
    transform: translateX(105%) scale(1);
  }
}

@media screen and (max-width: 900px) {
  .preselected {
    display: none;
  }

  .proselected {
    display: none;
  }
}

.true {
  transform: scale(1.4) !important;
  color: #ffffff !important;
}

.false {
  color: #797676 !important;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.slick-list {
  padding: 0px 60px 0 25px !important;
  overflow: hidden;
  margin-top: 14px;
}

.slick-track {
  display: flex;
}

.slick-slide.slick-active {
  opacity: 1;
  pointer-events: auto;
}

.slick-slide {
  opacity: 0.4;
  pointer-events: none;
}

.slick-arrow {
  display: none !important;
}

.slick-dots {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  list-style: none;
  padding: 0;
}

.slick-dots li:nth-child(2) {
  margin: 2px !important;
}

.slick-dots li button {
  background: none !important;
  outline: none !important;
  border: none;
  font-size: 36px !important;
  display: flex !important;
  align-items: center !important;
  color: #474747 !important;
  padding: 1px 3px !important;
  opacity: 0.6 !important;
}

.slick-dots li.slick-active button {
  color: #3c3c3c !important;
  opacity: 1 !important;
  transform: scale(1.4) !important;
  padding-bottom: 3px !important;
}

/* main header */
.main {
  font-weight: bold;
}

.bookshelf {
  font-weight: bold;
}

.community {
  font-weight: bold;
}

.friends {
  font-weight: bold;
}
/* 메인 나눔명조 */
@import url("https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap");

.sc-jdhxzS,
.sc-eBTqMF,
.sc-dGXzYd,
.toMyBookShelf {
  font-family: Sans KR, sans-serif;
}
